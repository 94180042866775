import { LOCALES } from './i18nConfig';
import urlJoin from 'url-join';
import getStateSlug from './utils/api/getStateSlug';
import {
  getAreaSlug,
  getCitySlug,
  getDistrictSlug,
  getNameSlug,
  getStreetSlug,
} from './utils/api/slugs';
import { LocationDetail } from './utils/types';

export const ROUTES = {
  blogPost: (id: string): string => `/blog/${id}/`,
  APILocations: '/api/locations/',
  APILocationsFees: '/api/locations/fees/',
  APIUserLocation: '/api/user-location/',
  APICryptos: '/api/cryptos/',
  APICryptoChanges: (coin: string): string => `/api/crypto-changes/${coin}/`,
  APINearestATMS: (region?: string): string => `/api/nearest-atms/${region || ''}/`,
  APIHostATMForm: '/api/host-atm-form/',
  APIContactUsForm: '/api/contact-us-form/',
  APITransactionById: (transactionId: string): string =>
    `/api/transaction-by-id?transactionId=${transactionId}/`,
  APITransactionsByWallet: '/api/transactions-by-wallet/',
  APISurveySubmit: '/api/survey/submit/',
  APISurveySubmitDirectFeedback: '/api/survey/submit/direct-feedback/',
  eTransferExchangeRate: urlJoin(
    `${process.env.NEXT_PUBLIC_SELL_CRYPTO_API_URL}`,
    '/api/sell-crypto/e-transfer/exchange-rate',
  ),
  state: (stateSlug: string): string => `/bitcoin-atm/${stateSlug}/`,
  area: (areaSlug: string): string => `/bitcoin-atm/${areaSlug}/`,
  city: (stateSlug: string, citySlug: string): string => `/bitcoin-atm/${stateSlug}/${citySlug}/`,
  district: (areaSlug: string, districtSlug: string): string =>
    `/bitcoin-atm/hong-kong/${areaSlug}/${districtSlug}/`,
  fullAddress: (location: LocationDetail): string => {
    if (location.country === 'Hong Kong') {
      return `${location.street}, ${location.district}, ${location.country}`;
    }

    return `${location.street}, ${location.city}, ${location.zip}, ${location.country}`;
  },
  location: (location: LocationDetail): string => {
    if (location.country === 'Hong Kong') {
      return `/bitcoin-atm/hong-kong/${getAreaSlug(location.area)}/${getDistrictSlug(location.district)}/${getNameSlug(location.place)}-at-${getStreetSlug(location.street)}/`;
    }

    return `/bitcoin-atm/${getStateSlug(location)}/${getCitySlug(location.city)}/${getNameSlug(location.place)}-at-${getStreetSlug(location.street)}/`;
  },
  bitcoinATM: (lang: string) =>
    lang === LOCALES.EN_HK || lang === LOCALES.ZH_HK ? '/bitcoin-atm/hong-kong/' : '/bitcoin-atm/',
  InteracLink: '/e-transfer-sell/',
  guidePost: (id: string): string => `/guide/${id}/`,
  sellCryptoSendVerificationSms: urlJoin(
    `${process.env.NEXT_PUBLIC_SELL_CRYPTO_API_URL}`,
    '/api/sell-crypto/send-verification-sms',
  ),
  contact: '/contact/',
  onlineSell: (id: string): string => `/online-sell/${id}/`,
  onlineSellETransferRoot: '/online-sell/e-transfer/',
  survey: '/survey/',
  surveyCompleted: '/survey/completed/',
  surveyDirectFeedback: '/survey/completed/direct-feedback/',
};
